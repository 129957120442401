import Enum from '../enum'

/**
 * スケジュール 種類
 *
 * 1:出張撮影 2:スタジオ撮影 3:打ち合わせ 4:予備日 8:休暇 9:その他]
 */
class ScheduleType extends Enum {
    static SHOOTING = 1; // 出張撮影
    static EVENT_SHOOTING = 2; // スタジオ撮影
    static MEETING = 3; // 打ち合わせ
    static SPARE = 4; // 予備日
    static OTHERS = 9; // その他

    static ABSENCE = 8; // 休暇

    static values() {
        return {
            [this.SHOOTING]: '出張撮影',
            [this.EVENT_SHOOTING]: 'スタジオ撮影',
            [this.MEETING]: '打ち合わせ',
            [this.SPARE]: '予備日',
            [this.ABSENCE]: '休暇',
            [this.OTHERS]: 'その他',
        }
    }

    static userValues() {
        return {
            [this.SHOOTING]: '出張撮影',
            [this.EVENT_SHOOTING]: 'スタジオ撮影',
            [this.MEETING]: '打ち合わせ',
            [this.OTHERS]: 'その他',
        }
    }

    static userOptions() {
        let object = this.userValues();
        let array = [];
        let keys = Object.keys(object);
        keys.forEach((key) => {
            array.push({
                key: parseInt(key, 10),
                label: object[key],
            });
        });

        return array;
    }
}

export default ScheduleType;